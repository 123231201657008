import specializationsTime from '@/api/study/specializations_time/specializations_time';
import fileDownload from "js-file-download";

const specializations_time = new specializationsTime();

const specializationsTimeModule= {

    namespaced: true,
    state: {
        item: null,
        items: [],
        total: 0,
        lookups: null,
        load: false,
        rolesLoad: false,
    },
    getters: {
        items(state) {
            return state.items;
        },
        item(state) {
            return state.item;
        },
        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        status(state) {
            return state.item.removed;
        },
        lookups(state) {
            return state.lookups;
        },
    },

 
    mutations: {
        SET_ITEMS: (state, users) => {
            state.items = users;
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total;
        },
        SET_ROLES_LOAD(state, isLoading) {
            state.rolesLoad = isLoading;
          },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_ITEM: (state, data) => {
            state.item = data;
        },
        
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
        },
    },
    actions: {
    
        specializationsTimelist({ commit }, payload) {
            return new Promise((resolve, reject) => {
              commit('SET_ITEMS_LOAD', true)
              specializations_time.specializationsTimelist(payload.query)
                .then(response => {
                  commit('SET_ITEMS', response.data)
                  commit('SET_TOTAL_ITEMS', response.meta.count)
                  commit('SET_LOOKUPS', response.meta.lookup)
                  commit('SET_ITEMS_LOAD', false)
                  resolve()
                })
            })
          },

          lookups({ commit },payload) {
            console.log('Lookups payload:', payload);
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                specializations_time.lookups(payload)
                    .then(response => {
                        console.log('Lookups Response:', response); // Check the full response
                        const lookupsData = response.data.lookups;
        
                        // Ensure you commit the correct data
                        commit('SET_LOOKUPS', lookupsData);
                        commit('SET_ITEMS_LOAD', false);
                        resolve();
                    })
                    .catch(error => {
                        console.error('Error fetching lookups:', error);
                        commit('SET_ITEMS_LOAD', false);
                        reject(error);
                    });
            });
        },        
        put({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
                specializations_time.put(payload.id, payload.query).then(response => {
                    console.log('Response:', response); // Check the full response
                    commit('app/UPDATE_LOAD', false, { root: true });
                    resolve(response);
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    reject(error);
                });
            });
        },
      

        get({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                specializations_time.get(id).then(response => {
                    commit('SET_ITEM', response.data);
                    if (response && response.data && typeof response.data == 'object') {
                        commit('app/UPDATE_PAGE_DETAILS', response.data, { root: true });
                    }

                    commit('SET_ITEMS_LOAD', false);
                    resolve(response);
                });
            });
        },
        
        remove({ commit }, id) {
            return new Promise((resolve, reject) => {
                specializations_time.remove(id).then(response => {
                    resolve();
                })
            });
        },
       preview({ commit }, payload) {
  return new Promise((resolve, reject) => {
    commit('SET_ITEMS_LOAD', true);
    specializations_time.preview(payload)
      .then(response => {
        commit('SET_ITEM', response.data);
        if (response && response.data && typeof response.data === 'object') {
          commit('app/UPDATE_PAGE_DETAILS', response.data, { root: true });
        }
        commit('SET_ITEMS_LOAD', false);
        resolve(response);
      })
      .catch(error => {
        commit('SET_ITEMS_LOAD', false);
        reject(error);
      });
  });
},
        approve({ commit }, id) {
            commit('app/UPDATE_LOAD', true, { root: true });
            console.log(id)
            return new Promise((resolve, reject) => {
                specializations_time
                    .approve(id) 
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.error('Approval failed:', error);
                        reject(error);
                    });
            });
        },
      export({ commit },{payload,programId}) {
    return new Promise((resolve, reject) => {
        console.log('Export payload:', payload);
        commit('SET_ROLES_LOAD', true);
        specializations_time
            .export({ payload, programId })
            .then(response => {
                fileDownload(response, `students_data.xlsx`); // Use backticks for template literals
                commit('SET_ROLES_LOAD', false);
                resolve(response);
            })
            .catch(error => {
                commit('SET_ROLES_LOAD', false); // Ensure loading state is reset even on error
                reject(error);
            });
    });
},
       
        
        restore({ commit }, id) {
            return new Promise((resolve, reject) => {
                specializations_time.restore(id).then(response => {
                    resolve();
                })
            });
        },
    },
};

export default specializationsTimeModule;
