import Resource from '../../resource'
import request from '../../../utils/request'

class UniversitiesList extends Resource {
  constructor() {
    super('universities-list')
  }

  list(query) {
    return request({
      url: `/${this.uri}/universities`,
      method: 'get',
      params: query,
    })
  }

//   edit(resource) {
//     return request({
//       url: `/${this.uri}/${resource.id}`,
//       method: 'post',
//       data: resource,
//     })
//   }
put(id, query) {
  return request({
    url: `/${this.uri}${id ? '/' + id : ''}`,
    method: 'put',
    data: query,
  })
}
  delete(id) {
    return request({
      url: `/${this.uri}/${id}`,
      method: 'delete',
    }) 
  }
export(payload){
  return request({
    url: `/${this.uri}/export`,
    method: 'post',
    responseType: "blob",
    data:payload,
  });
}
}

export { UniversitiesList as default }
